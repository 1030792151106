import Image from "next/image";
import Link from "next/link";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { serverAPI, actionS3Objects, publicS3Bucket } from "../utils/envConfig";
import { Markup } from "react-render-markup";

const headers = {
    "Content-Type": "application/json",
};

function truncate(str, n = 146) {
    return str.length > n ? str.substr(0, n - 1) + " ..." : str;
}

const BlogCard = ({ blog }) => {
    const [src, setSrc] = useState("/img/logo-black.png");

    const blogImageUrl = useMemo(() => (
        `https://${publicS3Bucket}/${blog?.image_link}`
    ), [blog?.image_link]);
    
    useEffect(() => setSrc(_ => blogImageUrl) , [blogImageUrl]);

    return (
        <>
            <Link href={ `/blogs/${ blog.slug }` }       > 
                <a
                    className={`flex flex-col  overflow-hidden md:pb-4 rounded-lg shadow-customnavy mx-2 `}
                >
                    <div className="flex-shrink-0 overflow-hidden">
                        <div className=" scale-100 transition-all duration-400 hover:scale-110">
                            <Image
                                width={800}
                                height={533}
                                className="object-fill w-full"
                                src={src}
                                onError={() => setSrc(_ => "/img/logo-black.png")}
                                alt={blog?.image_alt}
                                placeholder="empty"
                                // blurDataURL={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkZWB4AAAA/gDnnR27sAAAAABJRU5ErkJggg=="}
                            />
                        </div>
                    </div>
                    <div className="rounded-b-lg flex-1 p-6 flex flex-col">
                        <div className="flex-1">
                            <a
                                href={`/blogs/${blog.slug}`}
                                className="block mt-2"
                            >
                                <p className="text-xl font-semibold text-gray-900 hover:cursor-pointer hover:underline line-clamp-2">
                                    {blog.title}
                                </p>
                                <p className="mt-3 text-base text-gray-500 line-clamp-3">
                                    <Markup
                                        markup={truncate(blog?.meta_description ??"", 146)}
                                    />
                                </p>
                            </a>
                        </div>
                        <div className="mt-6 flex justify-center items-center">
                            <div className="flex flex-col gap-y-2 items-center">
                                <Link href={`/blogs/${blog.slug}`}>
                                    <a className="inline-flex items-center 2xl:px-8  px-8 2xl:py-2.5 py-3
                                     rounded-full  bg-night text-[#FFDEAB] hover:bg-[#DFC090] hover:text-navy cursor-pointer font-Lexend font-semibold">
                                        Read more
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        </>
    );
};

export default BlogCard;
