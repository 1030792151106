import Link from "next/link";
import BlogCard from "./BlogCard";
import { useEffect, useState } from "react";

import
{
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";

const Blog = ( {
    blogs,
    showLoadMore = true,

} ) =>
{
    const [ windowWidth, setWindowWidth ] = useState( null );

    const updateWindowWidth = ( e ) =>
    {
        setWindowWidth( window.innerWidth );
    };

    useEffect( () =>
    {
        setWindowWidth( window?.innerWidth );

        window.addEventListener( "resize", updateWindowWidth );

        return () =>
        {
            window.removeEventListener( "resize", updateWindowWidth );
        };
    }, [] );
    return (
        <div className="relative bg-white pt-8  sm:pt-16 pb-10 sm:pb-12 px-5 sm:px-6 lg:pt-16 lg:pb-10 lg:px-8">
            <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto xl:max-w-[1200px]">
                <div className="text-left xl:text-center sm:pb-6 2xl:pb-6">

                    <h3 className="text-navy font-Lexend">

                        <span className="font-bold 4xs:pl-4 3xl:pl-0 4xs:text-2xl 3xl:text-3xl">
                            Relevant
                        </span>
                        <span className="4xs:text-2xl 3xl:text-3xl">
                            { "" } Articles
                        </span>
                    </h3>
                </div>
                { windowWidth < 768 ? (
                    <div className="mt-4">
                        <Swiper
                            className="swiper2 bg-white h-full !pb-8"
                            modules={ [
                                Autoplay,
                                Navigation,
                                Pagination,
                                Scrollbar,
                                A11y,
                            ] }
                            spaceBetween={ 0 }
                            speed={ 1000 }
                            loop={ true }
                            slidesPerView={ 2 }
                            navigation
                            pagination={ { clickable: true } }
                            breakpoints={ {
                                200: { slidesPerView: 1 },
                                550: { slidesPerView: 2 },
                            } }
                            autoplay={ {
                                delay: 5000,
                                disableOnInteraction: false,
                                reverseDirection: false,
                            } }
                        >
                            { blogs &&
                                blogs?.map( ( blog ) => (
                                    <SwiperSlide key={ blog?.id ?? blog?._id }>
                                        <BlogCard blog={ blog } />
                                    </SwiperSlide>
                                ) ) }
                        </Swiper>
                    </div>
                ) : (
                    <div className="container mx-auto ">
                        <div className=" grid gap-4 lg:gap-5 lg:grid-cols-3 md:grid-cols-2 ">
                            { blogs &&
                                blogs.map(
                                    ( blog, index ) =>
                                        (
                                            <BlogCard
                                                key={ index }
                                                blog={ blog }
                                                windowWidth={ windowWidth }
                                            />
                                        )
                                ) }
                        </div>
                    </div>
                ) }

                { showLoadMore && (
                    <div className="text-center mt-6 md:mt-10 ">
                        <Link href="/blogs" passHref>
                            <a className="shadow-2xl">
                                <span className="inline-flex items-center 2xl:px-8   2xl:py-2.5 
                               font-bold bg-[#DFC090] text-night hover:bg-night hover:text-[#FFDEAB] rounded-full py-3 px-7 text-xl">
                                    Load more
                                </span>
                            </a>
                        </Link>
                    </div>
                ) }
            </div>
        </div>
    );
};

export default Blog;
